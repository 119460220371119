<script>
    export let flowType;
    
	const urlSearchParams = new URLSearchParams(window.location.search);
	const params = Object.fromEntries(urlSearchParams.entries());
	console.log(params)

	const flow = params["flow"]

	let fields = []
	let action = ""
	let method = ""
	let messages = []

	if (flow === undefined){
		window.location = `http://127.0.0.1:4433/self-service/${flowType}/browser`
	}

	fetch(`http://127.0.0.1:4433/self-service/${flowType}/flows?id=${flow}`, { credentials: "include" })
		.then(async response => { 
			const data = await response.json()

			switch(response.status) {
				case 200: // Success
					console.log("Success")
					console.log(data)
					action = data.ui.action
					method = data.ui.method
					fields = data.ui.nodes
					messages = data.ui.messages
					break
				case 410: // Flow expired
					console.log("Flow expired")
					console.log(data.error)
					window.location = data.error.details.redirect_to
					break
				default:
					console.log("Some other status")
					console.log(data)
			}
		})
	
</script>

<main>
	<h1>Sign in!</h1>
	{#if messages}
	<div class="messages">
		{#each messages as message}
			<p>{message.text}</p>
		{/each}
	</div>
	{/if}
	<form action="{action}" method="{method}">
		{#each fields as field}
            {#if field.meta.label !== undefined}
                <label for="{field.attributes.name}">{field.meta.label.text}</label>
            {/if}
            <input {...field.attributes}/><br/>
		{/each}
	</form>
</main>

<style>
	main {
		text-align: center;
		padding: 2em;
		max-width: 400px;
		width: 100%;
		margin: 0 auto;
		background: white;
	}

	.messages p {
		color: red;
	}

	h1 {
		color: #333;
		text-transform: uppercase;
		font-size: 2em;
		font-weight: 100;
	}

	label {
		margin-bottom: 5px;
	}

	input {
		width: 300px;
	}
</style>