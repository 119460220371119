<script>
  	import { Router, Route, Link } from "svelte-navigator";

	import AuthForm from "./AuthForm.svelte";
	import Verify from "./Verify.svelte";
	import Logout from "./Logout.svelte";
	import WhoAmI from "./WhoAmI.svelte"
</script>

<Router>
	<header>
		<Link to="/"> Home </Link>
		<Link to="login"> Login </Link>
		<Link to="register"> Register </Link>
		<Link to="recovery"> Recover </Link>
		<Link to="settings"> Settings </Link>
		<Link to="verify"> Verify </Link>
		<Link to="logout"> Logout </Link>
	</header>
	<main>
		<Route path="/">
			<WhoAmI></WhoAmI>
		</Route>
		<Route path="login">
			<AuthForm flowType="login"></AuthForm>
		</Route>
		<Route path="register">
			<AuthForm flowType="registration"></AuthForm>
		</Route>
		<Route path="verify">
			<Verify></Verify>
		</Route>
		<Route path="recovery">
			<AuthForm flowType="recovery"></AuthForm>
		</Route>
		<Route path="settings">
			<AuthForm flowType="settings"></AuthForm>
		</Route>
		<Route path="logout">
			<Logout></Logout>
		</Route>
	</main>
</Router>

<style>

</style>