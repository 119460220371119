<script>
let user = {}

fetch(`http://127.0.0.1:4433/sessions/whoami`, { credentials: "include" })
    .then(async response => { 
        user = await response.json()
    })

</script>

<main>
    <div>
        <pre>
            <code>
                {JSON.stringify(user, null, 2)}
            </code>
        </pre>
    </div>
</main>