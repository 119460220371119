<script>
	fetch(`http://127.0.0.1:4433/self-service/logout/browser`, { credentials: "include" })
		.then(async response => { 
			const data = await response.json()
			window.location = data.logout_url
		})
	
</script>

<main>
</main>